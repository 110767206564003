import MevrisLocalDeviceController from '@mevris/device-controller-mevris-local';
import MevrisRemoteDeviceController from '@mevris/device-controller-mevris-remote';

import { configs } from './configs';
import { plugins } from './plugins';
import { AppLoading } from './src/components';

export default {
	configs,
	plugins,

	deviceControllers: {
		MevrisLocalDeviceController,
		MevrisRemoteDeviceController,
	},

	LoadingComponent: AppLoading,
};
