import { View } from '@bluebase/components';
import { useTheme } from '@bluebase/core';
import React from 'react';

export interface PanelProps {
	children?: React.ReactNode;
}

export const Panel = ({ children }: PanelProps) => {
	const { theme } = useTheme();

	return (
		<View style={{
			backgroundColor: theme.palette.background.card,
			borderWidth: 1,
			borderColor: theme.palette.divider,
			// borderRadius: theme.shape.borderRadius,
			maxWidth: 600
		}}
		>
			{children}
		</View>
	);
};

Panel.displayName = 'Panel';
