import { ComponentState, WaitObserver } from '@bluebase/components';
import React from 'react';

export const UnauthenticatedError = () => {
	return (
		<WaitObserver>
			<ComponentState
				title="Unauthorized"
				description="You need to login to access this resource."
				imageSource="Error401"
			/>
		</WaitObserver>
	);
};
