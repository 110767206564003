import Constants from 'expo-constants';

import { VERSION } from './src/version';

export const configs = {
	debug: process.env.NODE_ENV !== 'production',
	development: process.env.NODE_ENV !== 'production',

	// App Developer
	author: 'BlueEast',

	// Website URL of App Developer
	authorUrl: 'https://www.blueeast.com',

	// App Title
	title: 'Mevris Embed',

	// App Version
	version: VERSION,

	// Apollo Graphql Configs
	'plugin.apollo.httpLinkOptions': {
		uri: Constants.expoConfig?.extra?.graphqlUrl,
	},

	'plugin.apollo.wsLinkOptions': {
		uri: Constants.expoConfig?.extra?.graphqlWsUrl,
	},

	// Config Persist Cache
	'plugin.config-persist.key': 'mevris-embed-config',
	// 'plugin.config-persist.encryptionEnable': true,
	// 'plugin.config-persist.encryptionKey': '123',
};
